


















import axios from "axios";
import {
    SfButton, SfLoader, SfProductCard
} from '@storefront-ui/vue';
import {
    defineComponent,
    ref,
    onMounted,
} from "@nuxtjs/composition-api";
import useApi from "~/composables/useApi";
import { CategoryTree } from "~/modules/GraphQL/types";
import CategoryProducts from "~/pages/CategoryProducts.gql";
import useWishlist from '~/modules/wishlist/composables/useWishlist';

import ProductsGrid from "~/modules/catalog/product/components/ProductsGrid.vue";
import {
    useProduct,
} from '~/composables';
export default defineComponent({
    name: "HighlightProduct",
    components: {
        SfProductCard,
        ProductsGrid,
        CategoryProducts: () =>
            import(/* webpackPrefetch: true */ "~/components/CategoryProducts.vue"),
        SfButton
    },
    setup() {
        const { query } = useApi();
        const categoryData = ref([]);
        const { isInWishlist, addOrRemoveItem } = useWishlist();
        const { getProductList, loading } = useProduct();
        const items = ref();
        const topContent = ref();
        const urlKey = ref();
        const catName = ref();
        const products = ref([]);

        const fetchHighlight = (async () => {
            const { data } = await query<{ categoryList: [CategoryTree] }>(CategoryProducts, {
                filters: {
                    url_key: { eq: 'highlight-product' }
                },
                pageSize: 8
            });
            data.categoryList.map((value, index) => {
                items.value = value.products;
                topContent.value = value?.top_content;
                urlKey.value = value?.url_key;
                catName.value = value?.name;
            });

        });

        onMounted(async () => {
            fetchHighlight();
        });

        return {
            categoryData,
            topContent,
            items,
            loading,
            urlKey,
            catName
        };
    },

});
